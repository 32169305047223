@import "../../../../styles/variables/colors.scss";

.fullContainer {
    display: flex;
    justify-content: space-evenly;
    align-items: center;
}
.footerLinks {
    flex-direction: row;
    align-items: flex-end;
    display: flex;
    justify-content: center;
    margin-top: auto;
    position: relative;
    left: 15px;
    white-space: nowrap;
    .links {
        text-decoration: none;
        font-family: "cl-regular";
        font-weight: 300;
        font-size: 12px;
        margin-right: 30px;
        color: $clara-blue;
    }
}

.container {
    max-width: 37.8rem;
    padding-top: 5.2rem;
    padding-bottom: 1.6rem;
    height: 98%;
    display: flex;
    flex-direction: column;
    .formContainer {
        max-width: 37.8rem;
        display: flex;
        flex-direction: column;
        align-items: center;
        div {
            max-width: 37.8rem;
            margin: 0;
        }
    }
    .loginTitle {
        display: flex;
        font-family: "cl-regular";
        font-weight: 400;
        text-align:center;
        font-size: 22px;
        color: $clara-blue;
        flex-direction: column;
        align-items: center;
    }
    .fieldContainer {
        margin-bottom: 30px !important;
        display: flex;
        flex-direction: column;
        align-items: center;
        padding-left: 3.1rem;
    }
    .termsAndConditions {
        display: flex;
        font-family: "cl-regular";
        font-weight: 300;
        font-size: 14px;
        color: $clara-dark-grey;
        flex-direction: column;
        margin-bottom: 14px !important;
        position: relative;
        right: 123px;
    }
    .submitButton {
        margin-top: 36px;
        display: flex;
        flex-direction: column;
        align-items: center;
        width: 37.8rem;
        position: relative;
        right: 10px;
        div {
            justify-content: center !important;
        }
        button {
            width: 200px;
            background-color: $clara-blue;
            font-family: "cl-regular";
            font-weight: 600;
            font-size: 14px;
        }
    }
    .logInButton {
        display: flex;
        flex-direction: column;
        align-items: center;
        font-family: "cl-regular";
        font-weight: 300;
        font-size: 12px;
        color: $clara-black;
        a {
            font-weight: 600;
            color: $clara-blue;
        }
    }
    .linkedInButton {
        display: flex;
        flex-direction: column;
        align-items: center;
        button {
            background-color: #3079b1;
            width: 200px;
            font-family: "cl-regular";
            font-weight: 500;
            font-size: 14px;
            color: $clara-white;
            border-radius: 4px;
            height: 40px;
        }
        .textBetweenButtons {
            margin-top: 18px;
            margin-bottom: 10px;
            font-family: "cl-regular";
            font-weight: 300;
            width: 100%;
            font-size: 14px;
            color: $clara-black;

            .text {
                margin: 20px;
            }
            .line {
                border-bottom: 1px $clara-light-blue solid;
                display: inline-block;
                width: 17.2308rem;
                margin-top: 8px;
            }
        }
        .signUpLink {
            font-family: "cl-regular";
            font-weight: 300;
            font-size: 12px;
            width: 75%;
            color: $clara-dark-grey;
            text-align: center;
            a {
                font-weight: 300;
                color: $clara-blue;
                text-decoration: none;
            }
        }
    }

    .firstTerms {
        margin-top: 40px !important;
        margin-bottom: 5px !important;
        div {
            div {
                div {
                    div {
                        label {
                            span {
                                a {
                                    color: $clara-blue;
                                    font-weight: 300;
                                    text-decoration: none;
                                }
                            }
                        }
                    }
                }
            }
        }
    }
    .subTitleTextContainer {
        display: flex;
        flex-direction: column;
        align-items: center;
        .firstText {
            margin-top: 13px;
            b {
                font-weight: 700;
            }
        }
        span {
            font-family: "cl-regular";
            font-weight: 300;
            font-size: 14px;
            color: $clara-black;
            text-align: center;
            margin-bottom: 13px;
        }
    }
    .notReceivedText {
        display: flex;
        flex-direction: column;
        align-items: center;
        span {
            font-family: "cl-regular";
            font-weight: 300;
            font-size: 14px;
            color: $clara-black;
        }
    }
    .resendButton {
        display: flex;
        flex-direction: column;
        align-items: center;
        margin-top: 15px !important;
        button {
            width: 200px;
            background-color: $clara-blue;
            font-family: "cl-regular";
            font-weight: 600;
            font-size: 14px;
        }
    }
    .verifyButton {
        margin-top: 40px !important;
        margin-bottom: 54px !important;
        button {
            margin-left: 0;
        }
    }
}

.verificationContainer {
    margin-top: 55px;
    height: 80%;
    display: flex;
    flex-direction: column;
    align-items: center;
    white-space: nowrap;
    position: relative;
    left: 1px;
    .footerLinks {
        margin-top: auto;
    }
}
.verificationField {
    white-space: nowrap;
    position: relative;
    right: 62px !important;
    .formCo
    div {
        max-width: 37.8rem !important;
    }
}
.errorMessage {
    font-family: "cl-regular";
    font-weight: 300;
    font-size: 12px;
    flex-direction: row;
    justify-content: flex-end;
    color: $clara-red;
    position: relative;
    top: 15px;
    left: 169px;
    svg {
        color: $clara-red;
        margin-left: 5px;
    }
}

.errorMessageVerification {
    font-family: "cl-regular";
    font-weight: 300;
    font-size: 12px;
    flex-direction: row;
    justify-content: flex-end;
    color: $clara-red;
    position: relative;
    top: 15px;
    left: 20px;
    svg {
        color: $clara-red;
        margin-left: 5px;
    }
}

.marketingEmail {
    color: red;
    div {
        div {
            div {
                div {
                    label {
                        span {
                            white-space: nowrap;
                        }
                    }
                }
            }
        }
    }
}

.verificationField {
    position: relative;
    right: 35px;
}

.footerSeparator{
    position: relative;
    right: 15px;
    color: $clara-light-blue;
}

.verifyNameContainer{
    margin-top: 4.5rem !important;
    padding-left: 0 !important;
    font-family: 'cl-regular';
    font-size: 14px;
    font-weight: 300;
    .verifyNameText {
        margin-bottom: 16px !important;
        
    }
}

.resendLink{
    color: $clara-blue;
    padding-top: 10px;
    cursor: pointer;
}