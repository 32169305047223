@import "./../../../../../styles/variables/colors.scss";
@import "./../../../../../styles/variables/texts.scss";
.Typography {
  margin: 0;
  padding: 0;
}
.regular {
  font-weight: 400;
}
.bold {
  font-weight: 700;
}
.semibold {
  font-weight: 600;
}
.light {
  font-weight: 300;
}
.xxxl {
  line-height: 6.153846rem; //80px
  font-size: 4.9231rem; //64px
  font-family: $font-family;
  b {
    font-family: "cl-extrabold";
    font-weight: 800;
  }
}
.xxl_2 {
  line-height: 3.0769rem; //40px
  font-size: 2.4615rem; //32px
  font-family: $font-family;
  b {
    font-family: "cl-extrabold";
  }
}
.xxl {
  line-height: 3.0769rem; //40px
  font-size: 2.4615rem; //32px
  font-family: $font-family;
  b {
    font-family: "cl-extrabold";
  }
}
.h1 {
  line-height: 3.0769rem; //40px
  font-size: 2.4615rem; //32px
  font-family: $font-family;
  b {
    font-family: "cl-extrabold";
  }
}
.h2 {
  line-height: 2.4615rem; //32px
  font-size: 1.8462rem; //24px
  font-family: $font-family;
  b {
    font-family: "cl-extrabold";
  }
}
.h3 {
  line-height: 1.8462rem; //24px
  font-size: 1.5385rem; //20px
  font-family: $font-family;
  b {
    font-family: "cl-extrabold";
  }
}
.h4 {
  line-height: 1.8462rem; //24px
  font-size: 1.3846rem; //18px
  font-family: $font-family;
  font-weight: unset;
  b {
    font-family: "cl-extrabold";
  }
}
.h5 {
  line-height: 1.8462rem; //24px
  font-size: 1.2308rem; //16px
  font-family: $font-family;
  b {
    font-family: "cl-extrabold";
  }
}
.xbody {
  line-height: 1.8462rem; //24px
  font-size: 1.3846rem; //18px
  font-family: $font-family;
  font-weight: unset;
  b {
    font-family: "cl-extrabold";
  }
}
.body {
  line-height: 1.8462rem; //24px
  font-size: 1.0769rem; //14px
  font-family: $font-family;
  b {
    font-family: "cl-extrabold";
  }
}

.bodyLineThrough {
  line-height: 1.8462rem; //24px
  font-size: 1.0769rem; //14px
  font-family: $font-family;
  text-decoration: line-through;
  b {
    font-family: "cl-extrabold";
  }
}

.m {
  line-height: 1.2308rem; //16px
  font-size: 1.0769rem; //14px
  font-family: $font-family;
  b {
    font-family: "cl-extrabold";
  }
}
.p{
  line-height: 1.5rem; //16px
  font-size: 1.2rem;; //14px
  font-family: $font-family;
  b {
    font-family: "cl-regular";
  }
}
.dash {
  line-height: 1.4308rem; //16px
  font-size: 1.0769rem; //14px
  font-family: $font-family;
  b {
    font-family: "cl-extrabold";
  }
}

.s {
  line-height: 1.2308rem; //16px
  font-size: 0.9231rem; //12px
  font-family: $font-family;
  b {
    font-family: "cl-extrabold";
  }
}
.xs {
  line-height: 1.2308rem; //16px
  font-size: 0.7692rem; //10px
  font-family: $font-family;
  b {
    font-family: "cl-extrabold";
  }
}
.xxs {
  line-height: 0.9231rem; //12px
  font-size: 0.6154rem; //8px
  font-family: $font-family;
  b {
    font-family: "cl-extrabold";
  }
}

.xxxs {
  line-height: 0.9231rem; //12px
  font-size: 0.375rem; //6px
  font-family: $font-family;
  b {
    font-family: "cl-bold";
  }
}

.title {
  line-height: 0.9231rem; //12px
  font-size: 3.6923rem; //48px
  font-family: $font-family;
  b {
    font-family: "cl-bold";
  }
}

.onboarding-titles {
  font-size: 48px;
  font-weight: 500;
  font-family: $font-family;
  line-height: 1.2;
  b {
    font-family: "cl-bold";
    font-weight: 700;
  }
}

.onboarding-titles-bold {
  font-size: 48px;
  font-weight: 700;
  font-family: "cl-bold";
  line-height: 1.2;
}

.onboarding-great {
  line-height: 28px; //28px
  font-size: 1.5385rem; //20px
  font-family: $font-family;
  b {
    font-family: "cl-extrabold";
  }
}

.light {
  font-family: "cl-light";
}
.regular {
  font-family: "cl-regular";
}
.bold {
  font-family: "cl-bold";
}
.extrabold {
  font-family: "cl-extrabold";
}
.semibold {
  font-family: "cl-semibold";
}
